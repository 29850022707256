import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Box,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from '@mui/material';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  FileCopy as FileCopyIcon,
  ExpandLess,
  ExpandMore,
  Save as SaveIcon,
  Edit as EditIcon,
  Cancel as CancelIcon,
  InfoOutlined as InfoIcon
} from '@mui/icons-material';

import { createTheme } from '@mui/material/styles';

// You can use the theme in your components by wrapping them with the ThemeProvider
import { ThemeProvider } from '@mui/material/styles';

//Drag/Drop
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4F5C63', // Your custom primary color
    },
    secondary: {
      main: '#64C8BD', // Your custom secondary color
    },
    // Add more custom colors if needed
    // For example: info, success, warning, error
  },
});

const App = () => {
  const [scenarios, setScenarios] = useState([]);
  const [newScenario, setNewScenario] = useState({
    name: '',
    given: [''],
    when: '',
    then: [''],
    selectedOption: 'Apex', // Initialize with the default value
  });

  /**
   * State Variables
   */
  // New state to track the open/closed state of each scenario
  const [isAllExpanded, setIsAllExpanded] = useState(false); // Initially, all scenarios are expanded
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0); //Scroll Position to return to when you are done editing BDD
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [scenarioToDelete, setScenarioToDelete] = useState(null);
  const [scenarioIndexToDelete, setScenarioIndexToDelete] = useState(null);

  /**
   * Local Storage functions (only works if hosting, Localhost won't store this data)
   */
  // Function to save scenarios to the browser's cache (localStorage)
  const saveScenariosToCache = useCallback(() => {
    localStorage.setItem('bddScenarios', JSON.stringify(scenarios));
  }, [scenarios]);

  // Function to retrieve scenarios from the browser's cache (localStorage)
  const getScenariosFromCache = () => {
    const cachedScenarios = localStorage.getItem('bddScenarios');
    return cachedScenarios ? JSON.parse(cachedScenarios) : [];
  };

  /**
   * Use Effect Methods
   */
  useEffect(() => {
    setScenarios(getScenariosFromCache());
  }, []); // Run only on component mount

  useEffect(() => {
    saveScenariosToCache();
  }, [scenarios, saveScenariosToCache]); // Run whenever scenarios or saveScenariosToCache function changes

  /**
   * Expand/Collapse Methods
   */
  const handleToggleExpand = (index) => {
    setScenarios((prevScenarios) => {
      const updatedScenarios = [...prevScenarios];
      updatedScenarios[index].expanded = !updatedScenarios[index].expanded;
      return updatedScenarios;
    });
  };

  const handleExpandCollapseAll = () => {
    setIsAllExpanded((prevValue) => !prevValue);
    setScenarios((prevScenarios) => {
      const updatedScenarios = prevScenarios.map((scenario) => ({ ...scenario, expanded: !isAllExpanded }));
      return updatedScenarios;
    });
  };

  const handleAddScenario = () => {
    setScenarios((prevScenarios) => [
      ...prevScenarios,
      {
        name: newScenario.name,
        given: [...newScenario.given],
        when: newScenario.when,
        then: [...newScenario.then],
        expanded: isAllExpanded, // Set the expanded property to match the state of "Expand All" / "Collapse All"
        selectedOption: newScenario.selectedOption,
      },
    ]);
    setNewScenario({
      name: '',
      given: [''],
      when: '',
      then: [''],
      selectedOption: 'Apex',
    });
  };

  const handleCopyScenario = (scenario, index) => {
    const exportData = `${index + 1}. ${scenario.name}\nType: ${scenario.selectedOption}\nGIVEN ${scenario.given.join('\nAND ')}\nWHEN ${scenario.when}\nTHEN ${scenario.then.join('\nAND ')}`;

    navigator.clipboard.writeText(exportData).then(
      () => {
        showSnackbar();
      },
      (error) => {
        console.error('Failed to copy BDD scenario:', error);
      }
    );
  };

  // Function to copy all scenarios to the clipboard
  const handleExportAll = () => {
    const exportData = scenarios
      .map((scenario, index) => {
        const given = scenario.given.filter(Boolean).map((item) => `${item}`).join('\nAND ');
        const then = scenario.then.filter(Boolean).map((item) => `${item}`).join('\nAND ');
        return `${index + 1}. ${scenario.name}\nType: ${scenario.selectedOption}\nGIVEN ${given}\nWHEN ${scenario.when}\nTHEN ${then}`;
      })
      .join('\n\n');

    navigator.clipboard.writeText(exportData).then(
      () => {
        showSnackbar();
      },
      (error) => {
        console.error('Failed to copy BDD scenarios:', error);
      }
    );
  };

  // Function to handle editing a scenario
  const handleEditScenario = (index) => {
    setIsEditing(true);
    setEditIndex(index);
    const { name, given, when, then, selectedOption } = scenarios[index];
    setNewScenario({
      name,
      given: [...given],
      when,
      then: [...then],
      selectedOption
    });
    handleScrollToTop();
  };

  // Function to handle updating an edited scenario
  const handleUpdateScenario = () => {
    setScenarios((prevScenarios) => {
      const updatedScenarios = [...prevScenarios];
      updatedScenarios[editIndex] = {
        name: newScenario.name,
        given: [...newScenario.given],
        when: newScenario.when,
        then: [...newScenario.then],
        selectedOption: newScenario.selectedOption,
        expanded: updatedScenarios[editIndex].expanded, // Preserve the expanded state
      };
      return updatedScenarios;
    });
    setIsEditing(false);
    setEditIndex(null);
    setNewScenario({
      name: '',
      given: [''],
      when: '',
      then: [''],
      selectedOption: 'Apex'
    });
    handleScrollBack();
  };

  // Function to handle canceling the edit mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditIndex(null);
    setNewScenario({
      name: '',
      given: [''],
      when: '',
      then: [''],
      selectedOption: 'Apex'
    });
    handleScrollBack();
  };

  /**
   * Snack Bar Methods
   */
  const showSnackbar = () => {
    setIsSnackbarOpen(true);
    setTimeout(() => {
      setIsSnackbarOpen(false);
    }, 1250); // 1.25 seconds
  };

  // Function to add an additional GIVEN or THEN text field
  const handleAddField = (fieldType) => {
    setNewScenario((prevScenario) => {
      if (fieldType === 'given') {
        return { ...prevScenario, given: [...prevScenario.given, ''] };
      } else if (fieldType === 'then') {
        return { ...prevScenario, then: [...prevScenario.then, ''] };
      }
    });
  };

  // Function to remove the last GIVEN or THEN text field, while ensuring at least one field remains
  const handleRemoveField = (fieldType) => {
    setNewScenario((prevScenario) => {
      if (fieldType === 'given' && prevScenario.given.length > 1) {
        const updatedGiven = prevScenario.given.slice(0, -1);
        return { ...prevScenario, given: updatedGiven };
      } else if (fieldType === 'then' && prevScenario.then.length > 1) {
        const updatedThen = prevScenario.then.slice(0, -1);
        return { ...prevScenario, then: updatedThen };
      }
      return prevScenario; // If only one field is present, return the current state as is
    });
  };

  // Function to update the value of a GIVEN or THEN text field
  const handleUpdateField = (index, fieldType, value) => {
    setNewScenario((prevScenario) => {
      if (fieldType === 'given') {
        const updatedGiven = [...prevScenario.given];
        updatedGiven[index] = value;
        return { ...prevScenario, given: updatedGiven };
      } else if (fieldType === 'then') {
        const updatedThen = [...prevScenario.then];
        updatedThen[index] = value;
        return { ...prevScenario, then: updatedThen };
      }
    });
  };

  /**
   * Scroll Functionality
   */
  const handleScrollToTop = () => {
    setScrollPosition(window.scrollY); // Store the current scroll position
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // This will make the scrolling smooth
    });
  };

  const handleScrollBack = () => {
    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  /**
   * Delete Modal + Confirmation
   */
  const handleOpenDeleteConfirmation = (scenario, index) => {
    setIsDeleteConfirmationOpen(true);
    setScenarioToDelete(scenario);
    setScenarioIndexToDelete(index);
  };

  const handleCloseDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
    setScenarioToDelete(null);
    setScenarioIndexToDelete(null);
  };

  const handleConfirmDelete = (index) => {
    const updatedScenarios = scenarios.filter((_, i) => i !== index);
    setScenarios(updatedScenarios);
    setIsDeleteConfirmationOpen(false);
    setScenarioToDelete(null);
  };

  /**
   * Info Modal + Confirmation
   */
  const handleOpenInfo = () => {
    setIsInfoOpen(true);
  };

  const handleCloseInfo = () => {
    setIsInfoOpen(false);
  };

  /**
   * Dropdown Methods
   */
  const handleDropdownChange = (event) => {
    setNewScenario({
      ...newScenario,
      selectedOption: event.target.value,
    });
  };

  /**
   * Drag n Drop Methods
   */
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedScenarios = Array.from(scenarios);
    const [movedScenario] = reorderedScenarios.splice(result.source.index, 1);
    reorderedScenarios.splice(result.destination.index, 0, movedScenario);

    setScenarios(reorderedScenarios);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ margin: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Super Awesome BDD Generator
        </Typography>

        <div style={{ marginBottom: '20px' }}>
          <TextField
            label="NAME"
            variant="outlined"
            value={newScenario.name}
            onChange={(e) => setNewScenario({ ...newScenario, name: e.target.value })}
            fullWidth
          />

          {/* Dropdown for scenario selection */}
          <Select
            label="Test Type"
            variant="outlined"
            value={newScenario.selectedOption}
            onChange={handleDropdownChange}
            fullWidth
            style={{ marginTop: '10px' }}
          >
            <MenuItem value="Apex">Apex</MenuItem>
            <MenuItem value="Jest">Jest</MenuItem>
            <MenuItem value="Manual QA">Manual QA</MenuItem>
            <MenuItem value="QA Automation">QA Automation</MenuItem>
          </Select>

          {/* Render multiple GIVEN text fields */}
          {newScenario.given.map((givenValue, index) => (
            <TextField
              key={`given-${index}`}
              label={`GIVEN ${index + 1}`}
              variant="outlined"
              value={givenValue}
              onChange={(e) => handleUpdateField(index, 'given', e.target.value)}
              fullWidth
              style={{ marginTop: '10px' }}
            />
          ))}
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleAddField('given')}>
          </Button>
          {newScenario.given.length > 1 && (
            <Button variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={() => handleRemoveField('given')} style={{ marginLeft: '20px' }}>
            </Button>
          )}

          <TextField
            label="WHEN"
            variant="outlined"
            value={newScenario.when}
            onChange={(e) => setNewScenario({ ...newScenario, when: e.target.value })}
            fullWidth
            style={{ marginTop: '10px' }}
          />

          {/* Render multiple THEN text fields */}
          {newScenario.then.map((thenValue, index) => (
            <TextField
              key={`then-${index}`}
              label={`THEN ${index + 1}`}
              variant="outlined"
              value={thenValue}
              onChange={(e) => handleUpdateField(index, 'then', e.target.value)}
              fullWidth
              style={{ marginTop: '10px' }}
            />
          ))}
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleAddField('then')}>
          </Button>
          {newScenario.then.length > 1 && (
            <Button variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={() => handleRemoveField('then')} style={{ marginLeft: '20px' }}>
            </Button>
          )}

          {isEditing ? (
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start' }}>
              <Button variant="contained" color="secondary" aria-label="save" startIcon={<SaveIcon />} onClick={handleUpdateScenario}>
                Save
              </Button>
              <Button variant="contained" color="primary" aria-label="cancel" startIcon={<CancelIcon />} onClick={() => handleCancelEdit()} style={{ marginLeft: '20px' }}>
                Cancel
              </Button>
            </div>
          ) : (
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start' }}>
              <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={handleAddScenario}>
                Add BDD
              </Button>
            </div>
          )}
        </div>

        <Button
          variant="contained"
          color="primary"
          startIcon={<FileCopyIcon />}
          onClick={handleExportAll}
          style={{ marginTop: '20px' }}
        >
          Copy All
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={isAllExpanded ? <ExpandLess /> : <ExpandMore />}
          onClick={handleExpandCollapseAll}
          style={{ marginTop: '20px', marginLeft: '20px' }}
        >
          {isAllExpanded ? 'Collapse All' : 'Expand All'}
        </Button>
        <IconButton
          aria-label="info"
          onClick={handleOpenInfo}
          title="Click for BDD Click/Drag Functinoality"
        >
          <InfoIcon />
        </IconButton>

        {/* <List style={{ marginTop: '20px' }}>
          {scenarios.map((scenario, index) => (
            <React.Fragment key={index}>
              <ListItem button onClick={() => handleToggleExpand(index)}
                style={{
                  border: '1px solid #ccc',
                  padding: '10px',
                  background: '#f9f9f9',
                  marginBottom: scenario.expanded ? '0' : '10px', // Apply marginBottom only when collapsed
                }}>
                <ListItemText
                  primary={`${scenario.name}`}
                  secondary={`Type: ${scenario.selectedOption}`}
                />
                <ListItemSecondaryAction>
                  {!isEditing && (
                    <>
                      <IconButton
                        aria-label="copy"
                        onClick={() => handleCopyScenario(scenario, index)}
                      >
                        <FileCopyIcon />
                      </IconButton>
                      <IconButton aria-label="edit" onClick={() => handleEditScenario(index)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" onClick={() => handleOpenDeleteConfirmation(scenario, index)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={scenario.expanded} timeout="auto" unmountOnExit>
                <Box sx={{ padding: '10px', border: '1px solid #ccc', background: '#f9f9f9', marginBottom: '10px' }}>
                  <Typography>
                    {`GIVEN ${scenario.given.join('\nAND ')}`}
                  </Typography>
                  <Typography>
                    {`WHEN ${scenario.when}`}
                  </Typography>
                  <Typography>
                    {`THEN ${scenario.then.join('\nAND ')}`}
                  </Typography>
                </Box>
              </Collapse>
            </React.Fragment>
          ))}
        </List> */}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="scenarios">
            {(provided, snapshot) => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {scenarios.map((scenario, index) => (
                  <Draggable key={index} draggableId={`scenario-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <div key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}>
                        <ListItem
                          button
                          onClick={() => handleToggleExpand(index)}
                          style={{
                            border: '1px solid #ccc',
                            padding: '10px',
                            background: '#f9f9f9',
                            marginBottom: scenario.expanded ? '0' : '10px',
                          }}
                        >
                          <ListItemText
                            primary={`${scenario.name}`}
                            secondary={`Type: ${scenario.selectedOption}`}
                          />
                          <ListItemSecondaryAction>
                            {!isEditing && (
                              <>
                                <IconButton
                                  aria-label="copy"
                                  onClick={() => handleCopyScenario(scenario, index)}
                                >
                                  <FileCopyIcon />
                                </IconButton>
                                <IconButton aria-label="edit" onClick={() => handleEditScenario(index)}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton aria-label="delete" onClick={() => handleOpenDeleteConfirmation(scenario, index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse in={scenario.expanded} timeout="auto" unmountOnExit>
                          <Box sx={{ padding: '10px', border: '1px solid #ccc', background: '#f9f9f9', marginBottom: '10px' }}>
                            <Typography>
                              {`GIVEN ${scenario.given.join('\nAND ')}`}
                            </Typography>
                            <Typography>
                              {`WHEN ${scenario.when}`}
                            </Typography>
                            <Typography>
                              {`THEN ${scenario.then.join('\nAND ')}`}
                            </Typography>
                          </Box>
                        </Collapse>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>

        {/* Snackbar to display "Copied to clipboard" message */}
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={1250}
          onClose={() => setIsSnackbarOpen(false)}
          message="Copied to clipboard"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </div>

      <Dialog open={isDeleteConfirmationOpen} onClose={handleCloseDeleteConfirmation}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          {scenarioToDelete && `Are you sure you want to delete the scenario: ${scenarioToDelete.name}?`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleConfirmDelete(scenarioIndexToDelete);
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isInfoOpen} onClose={handleCloseInfo}>
        <DialogTitle>BDD Scenario Click/Drag Functionality</DialogTitle>
        <DialogContent>
          Click a BDD scenario to expand it.
          Click and drag a BDD scenario to reorder it.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseInfo}
            color="primary"
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider >
  );
};

export default App;